import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Snackbar, LinearProgress, Toast } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';
const WebsiteContainer = React.lazy(() => import('./pages/WebsiteContainer'));
const AccountSettings = React.lazy(() => import('./pages/AccountSettings'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      toastMsg: '',
      toastStatus: '',
    };
    this.logout = this.logout.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  logout() {
    this.showToast('Logging Out...', 'success');
  }
  showToast(message, status) {
    this.setState({ toastMsg: message, toastStatus: status });
  }
  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ toastStatus: null });
  }
  render() {
    const { toastStatus, toastMsg } = this.state;
    return (
      <div className="App">
        <Router basename={process.env.PUBLIC_URL}>
          <React.Suspense
            fallback={<LinearProgress color="secondary" style={{ margin: '30% auto 0', width: '88%' }} />}
          >
            <Route
              exact
              path="/"
              render={(props) => (
                <WebsiteContainer
                  {...props}
                  toastStatus={toastStatus}
                  logout={this.logout}
                  showToast={this.showToast}
                />
              )}
            />
            <Route
              exact
              path="/settings"
              render={(props) => <AccountSettings {...props} logout={this.logout} showToast={this.showToast} />}
            />
          </React.Suspense>
        </Router>
        {toastStatus && (
          <Snackbar open={toastStatus} autoHideDuration={3000} onClose={this.handleClose}>
            <MuiAlert onClose={this.handleClose} severity={toastStatus}>
              {toastMsg}
            </MuiAlert>
          </Snackbar>
        )}
      </div>
    );
  }
}

export default App;
